// import Cookies from 'js-cookie'
// import { CONFIG } from 'configs'
import { get_local_storage, set_local_storage } from "./storage"

// const { TOKEN_KEY, TOKEN_COOKIE_EXPIRES } = CONFIG

// const get_token_cookies = () => Cookies.get(TOKEN_KEY)

const get_token_storage = () => get_local_storage("token")

// const set_token_cookies = (token, remember = false) => {
// 	if (remember) {
// 		return Cookies.set(TOKEN_KEY, token, { "expires": TOKEN_COOKIE_EXPIRES })
// 	}
// 	return Cookies.set(TOKEN_KEY, token)
// }

const set_authenticated_storage = ({ access_token, username, id, roles, department, garage, garage_status, main_permission, sub_permission,
	department_plan_type, department_exprire_date, department_plan_status, department_name, department_plan_id }, remember_me = true) => {
	// const expires = new Date().getTime()
	// set_local_storage("token", gara_plan_status == 1 ? access_token : undefined)
	set_local_storage("token", access_token)
	set_local_storage("username", username)
	set_local_storage("id", id)
	set_local_storage("roles", roles)
	set_local_storage("department", department)
	set_local_storage("department_name", department_name)
	set_local_storage("department_exprire_date", department_exprire_date)
	set_local_storage("department_plan_type", department_plan_type)
	set_local_storage("department_plan_status", department_plan_status)
	set_local_storage("department_plan_id", department_plan_id)
	set_local_storage("garage", garage)
	set_local_storage("garage_status", garage_status)
	set_local_storage("main_permission", main_permission)
	set_local_storage("sub_permission", sub_permission)
}
const render_used = (data) => {
	const endDate = new Date(data?.plan?.end_date);
	endDate.setHours(0, 0, 0, 0);
	const currentDate = new Date();
	currentDate.setHours(0, 0, 0, 0);
	const dayRemaining = Math.floor((endDate - currentDate) / (1000 * 60 * 60 * 24) + 1);
	const storageRemaining = (data?.storage_used / data?.storage_total * 100)
	set_local_storage("department_data", data)
	dayRemaining < 4 && set_local_storage("day_remaining", dayRemaining)
	storageRemaining > 80 && set_local_storage("storage_remaining", storageRemaining)

	if (dayRemaining < 4 || storageRemaining > 80) {
	 return	set_local_storage("show_noti_date", true);
	}
	return set_local_storage("show_noti_date", '');
}

// const remove_token_cookies = () => Cookies.remove(TOKEN_KEY)

const remove_token_storage = () => localStorage.clear()

const is_authenticated = () => {
	// return true
	try {
		const token = get_token_storage('token')
		if (token) {
			return true
		}
		return false

		// const expires_at = get_local_storage('expires_at')
		// const now = new Date().getTime()
		// if (!is_empty(token) && !is_empty(expires_at) && (now < expires_at)) {
		// 	return true
		// } else {
		// 	// remove_token_storage()
		// 	return false
		// }
	} catch (error) {
		// remove_token_storage()
		return false
	}
}

const has_permission = (role = []) => {
	return true

	// if (role && role instanceof Array && role.length > 0) {
	// 	const roles = get_local_storage("user").roles
	// 	const permission_roles = role
	// 	const has_permission = roles.some(r => {
	// 		return permission_roles.includes(r)
	// 	})

	// 	if (!has_permission) {
	// 		return false
	// 	}

	// 	return true
	// } else {
	// 	console.error(`need roles! Like ['admin','editor']`)
	// 	return false
	// }
}

export { get_token_storage, set_authenticated_storage, remove_token_storage, is_authenticated, has_permission, render_used }