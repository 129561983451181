/**
|--------------------------------------------------
| TEMPLATE IMAGE FALLBACK
| Ex: <ImageView src={url} classname={classname} style={style} alt={alt} />
| @vunv

|--------------------------------------------------
*/
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CONFIG } from "configs"
import { is_empty } from "utils"

const ImageView = (props) => {
	const [failed, setFailed] = useState(false)
	const { src, classname, style, alt } = props

	// img base 64
	if (!is_empty(src) && src.includes("data:image/pngbase64") && src.length > 30) {
		return (<img
			src={src}
			className={classname}
			style={style}
			data-loading="lazy"
			alt={alt}
			loading="lazy"
		/>)
	}

	// if img error -> show img fallback
	if (failed || is_empty(src)) {
		return (<img
			src={CONFIG.IMG_FALL_BACK}
			alt={alt}
			className={classname}
			style={style}
			data-loading="lazy"
			loading="lazy"
		/>)
	}

	// img origin load first
	return (<img
		onError={(e) => setFailed(true)}
		src={src}
		className={classname}
		style={style}
		data-loading="lazy"
		alt={alt}
		loading="lazy"
	/>)
}

ImageView.propTypes = {
	src: PropTypes.string,
	classname: PropTypes.string,
	style: PropTypes.object,
	alt: PropTypes.string
}

ImageView.defaultProps = {
	src: '/assets/fallback-image.gif',
	classname: 'exif_orientation_img',
	style: {},
	alt: 'img'
}

export default React.memo(ImageView)