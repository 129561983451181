const ENV = process.env.REACT_APP_STAGE
const HTTP = process.env.REACT_APP_HTTP
const WS = process.env.REACT_APP_WS
const SERVER_API_IP = process.env.REACT_APP_SERVER_API
const SERVER_LIVESTREAM_IP = process.env.REACT_APP_SERVER_LIVESTREAM
const SERVER_LIVESTREAM_TURN = process.env.REACT_APP_SERVER_TURN
const DOMAIN = process.env.REACT_APP_DOMAIN
const PORT_API_JSON = process.env.REACT_APP_PORT_API_JSON
const PORT_API_UPLOAD = process.env.REACT_APP_PORT_API_UPLOAD
const PORT_API_FILE = process.env.REACT_APP_PORT_API_FILE
const PORT_SOCKET_CHAT = process.env.REACT_APP_PORT_SOCKET_CHAT
const SERVER_PORT = process.env.REACT_APP_PORT
const PORT_LIVESTREAM = process.env.REACT_APP_PORT_LIVESTREAM
const PORT_LIVESTREAM_FILE = process.env.REACT_APP_PORT_LIVESTREAM_FILE
const FACEBOOK_APP = process.env.REACT_APP_FACEBOOK_APP_ID
const GOOGLE_APP = process.env.REACT_APP_GOOGLE_APP_ID


//CHECK
const SERVER_API = process.env.REACT_APP_BASE_API
//
// const SERVER_API = `${HTTP}://${SERVER_API_IP}:${PORT_API_JSON}`
const SERVER_API_UPLOAD = `${HTTP}://${SERVER_API_IP}:${PORT_API_UPLOAD}`
const SERVER_SOCKET_CHAT = `${WS}://${SERVER_API_IP}:${PORT_SOCKET_CHAT}`
const BASE_URL = `//${DOMAIN}:${SERVER_PORT}`
const SERVER_LIVESTREAM = `${WS}://${SERVER_LIVESTREAM_IP}:${PORT_LIVESTREAM}`
const BASE_URL_FILE_API = `${HTTP}://${SERVER_API_IP}:${PORT_API_FILE}`
const BASE_URL_FILE_LIVESTREAM = `${HTTP}://${SERVER_LIVESTREAM_IP}:${PORT_LIVESTREAM_FILE}`

export {
	SERVER_API, BASE_URL_FILE_API, SERVER_API_UPLOAD,
	SERVER_SOCKET_CHAT,
	BASE_URL, ENV, SERVER_PORT,
	SERVER_LIVESTREAM,
	BASE_URL_FILE_LIVESTREAM,
	SERVER_LIVESTREAM_TURN,
	FACEBOOK_APP,
	GOOGLE_APP
}