/**
|--------------------------------------------------
| TREE CHECKBOX
| @vunv

|--------------------------------------------------
*/
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import CheckboxTree from 'react-checkbox-tree'
import { FormattedMessage } from "react-intl"
import { MdCheckBox, MdChevronRight, MdExpandMore, MdCheckBoxOutlineBlank, MdIndeterminateCheckBox } from "react-icons/md"
import './react-checkbox-tree.scss'

const CheckboxTreeMenu = (props) => {
	const [checked, setchecked] = useState([])
	const [expanded, setexpanded] = useState([])
	const _checked = (checked) => {
		props.checked(checked)
		setchecked(checked)
	}
	const _expanded = (expanded) => {
		props.expanded(expanded)
		setexpanded(expanded)
	}

	useEffect(() => {
		setchecked(props.list_checked)
		return () => { }
	}, [props.list_checked])

	return (<React.Fragment>
		{
			props.nodes
				? (<CheckboxTree
					nodes={props.nodes || []}
					checked={checked}
					expanded={expanded}
					onCheck={checked => _checked(checked)}
					onExpand={expanded => _expanded(expanded)}
					icons={{
						check: <MdCheckBox />,
						uncheck: <MdCheckBoxOutlineBlank />,
						halfCheck: <MdIndeterminateCheckBox />,
						expandClose: <MdChevronRight />,
						expandOpen: <MdExpandMore />,
						expandAll: <MdChevronRight />,
						collapseAll: <MdCheckBox />,
						// parentClose: <MdMoreHoriz />,
						// parentOpen: <MdMoreHoriz />,
						// leaf: <MdMoreHoriz />,
					}}
				/>)
				: (<FormattedMessage id="response.nodata" />)
		}

	</React.Fragment>)
}

CheckboxTreeMenu.propTypes = {
	nodes: PropTypes.array,
	list_checked: PropTypes.array,
	expanded: PropTypes.func,
	checked: PropTypes.func
}

CheckboxTreeMenu.defaultProps = {
	nodes: [],
	list_checked: [],
	checked: () => console.log("checked"),
	expanded: () => console.log("expanded")
}


export default React.memo(CheckboxTreeMenu)