/**
|--------------------------------------------------
| @vunv

|--------------------------------------------------
*/
import React, { Component } from 'react'
import Lightbox from "react-image-lightbox"
import { connect } from 'react-redux'
import { is_empty } from "utils"
import "react-image-lightbox/style.css"

class ImageViewLightbox extends Component {
	constructor(props) {
		super(props)
		this.state = {
			"is_open": false,
			"show_lightbox": [],
			"photo_index": 0
		}
	}

	static getDerivedStateFromProps(props, state) {
		if (props.show_lightbox && props.show_lightbox.payload && !is_empty(props.show_lightbox.payload) && (props.show_lightbox.payload !== state.show_lightbox)) {
			return { "show_lightbox": props.show_lightbox.payload }
		}
		return null
	}

	componentDidUpdate(prevProps, prevState) {
		const { show_lightbox } = this.state
		if (prevState.show_lightbox !== show_lightbox) {
			this.setState({ "is_open": true })
		}
	}

	render() {
		const { is_open, show_lightbox, photo_index } = this.state

		return (<div id="react-image-lightbox-view">
			{is_open && (
				<Lightbox
					mainSrc={show_lightbox[0]}
					// nextSrc={show_lightbox[(photo_index + 1) % show_lightbox.length]}
					// prevSrc={show_lightbox[(photo_index + show_lightbox.length - 1) % show_lightbox.length]}
					onCloseRequest={() => {
						this.setState({ "is_open": false, "show_lightbox": [], photo_index: (photo_index + show_lightbox.length - 1) % show_lightbox.length, })
						// this.on_close = this.props.on_close(true)
					}}
				// onMovePrevRequest={() =>
				// 	this.setState({
				// 		photo_index: (photo_index + show_lightbox.length - 1) % show_lightbox.length
				// 	})
				// }
				// onMoveNextRequest={() =>
				// 	this.setState({
				// 		photo_index: (photo_index + 1) % show_lightbox.length
				// 	})
				// }
				/>
			)}
		</div>)
	}
}

const mapStateToProps = (state) => {
	return {
		show_lightbox: state.show_lightbox_reducer
	}
}

export default connect(mapStateToProps, {})(ImageViewLightbox)
