/**
|--------------------------------------------------
| IFRAME
| Ex: <Iframe src={url} />
| @vunv

|--------------------------------------------------
*/
import React from 'react'
import PropTypes from 'prop-types'
import { is_empty } from "utils"

const Iframe = (props) => {
	const { src } = props
	const height = document.documentElement.clientHeight - 100

	if (is_empty(src)) {
		return null
	}

	return (<iframe src={src} frameBorder="no" style={{ width: '100%', height: `${height}px` }} scrolling="auto" title="iframe" />)
}

Iframe.propTypes = {
	src: PropTypes.string
}

Iframe.defaultProps = {
	src: ''
}

export default React.memo(Iframe)